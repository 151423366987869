/* eslint-disable no-console */
import MA from '../../api/marketing_automation';

const allowMA = process.env.NEXT_PUBLIC_ALLOW_MA === 'true';
const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const add = async (email, token) => {
  try {
    if (allowMA && email) {
      const user =
        typeof window !== 'undefined'
          ? JSON.parse(localStorage.getItem('user'))
          : null;
      let lead = await MA.getByEmail(email);
      if (lead.success) {
        if (!lead.data?.id) {
          // si no esta registrado como lead lo creamos y segmentamos
          lead = await MA.createContact(email, user?.name || null);
        }
        // buscar el segemento de carrito abandondado para esta store
        // si el usuario no esta en el segmento lo agregamos
        if (
          !lead?.data?.segments?.includes(
            `${storeId.toUpperCase}_ABANDONED_CART`
          )
        ) {
          await MA.editContactSegments(lead?.data?.id, storeId, true);
        }
        // si el usuario no tiene un carrito de esta store, se lo agregamos
        if (lead.data?.abandoned_carts?.[storeId] !== token) {
          await MA.updateContact(lead.data?.id, { token });
        }
        localStorage.setItem(
          'marketingAutomationID',
          JSON.stringify({ email, id: lead.data?.id })
        );
      }
    }
    return true;
  } catch {
    return true;
  }
};

const remove = async () => {
  try {
    const marketingAutomationID = JSON.parse(
      localStorage.getItem('marketingAutomationID')
    );
    if (allowMA && marketingAutomationID.id) {
      const lead = await MA.getByEmail(marketingAutomationID.email);
      if (lead?.data?.id) {
        MA.editContactSegments(lead.data.id, storeId, false);
      }
    }
    // eslint-disable-next-line no-empty
  } catch {}
};

const addToNewsletter = async (email, segmentIds) => {
  try {
    if (allowMA && email) {
      let lead = await MA.getByEmail(email);
      if (!lead.data?.id) {
        // si no esta registrado como lead lo creamos y segmentamos
        lead = await MA.createContact(email);
      }
      const response = await MA.updateSegments(lead.data?.id, segmentIds, true);
      if (response.success) {
        return { success: true };
      }
    }
    return { success: false };
  } catch (err) {
    return { success: false };
  }
};

export default {
  add,
  remove,
  addToNewsletter
  // crossSellMautic
};
