/* eslint-disable camelcase */

const sendEvent = async (name, options) => {
  if (window.vaypol_analytics) {
    window.vaypol_analytics.queue.push({ name, options });
  } else {
    window.vaypol_analytics = { queue: [{ name, options }], processing: false };
  }
};

const search = (keywords) => {
  sendEvent('search', {
    data: keywords
  });
};

const notFoundPage = (keywords) => {
  sendEvent('notFound', {
    data: keywords
  });
};

const pageview = (url) => {
  sendEvent('page_view', {
    data: url
  });
};

const viewProduct = (product_id) => {
  sendEvent('view_item', {
    product_id
  });
};

const viewLanding = (landing) => {
  sendEvent('view_landing', { data: landing });
};

const viewHome = () => {
  sendEvent('view_home');
};

const viewCart = () => {
  sendEvent('view_cart');
};

const addPaymentInfo = (type) => {
  sendEvent('add_payment_info', { data: type });
};

const personalData = (type) => {
  sendEvent('personal_data', {
    data: type
  });
};

const shippingData = (type) => {
  sendEvent('shipping_data', {
    data: type
  });
};

const billingData = (type) => {
  sendEvent('billing_data', {
    data: type
  });
};

const newCardAdded = () => {
  sendEvent('new_card_added');
};

const beginCheckout = () => {
  sendEvent('begin_checkout');
};

const addToCart = (product_id) => {
  sendEvent('add_to_cart', {
    product_id
  });
};

const removeFromCart = (product_id) => {
  sendEvent('remove_from_cart', {
    product_id
  });
};

const signup = () => {
  sendEvent('sign_up');
};

const purchase = (order_id) => {
  sendEvent('purchase', {
    order_id
  });
};

const purchaseTry = () => {
  sendEvent('purchase_try');
};

const conversion = (order_id) => {
  sendEvent('conversion', {
    order_id
  });
};

export default {
  search,
  pageview,
  viewProduct,
  viewLanding,
  viewHome,
  viewCart,
  addPaymentInfo,
  beginCheckout,
  addToCart,
  removeFromCart,
  signup,
  purchase,
  purchaseTry,
  conversion,
  notFoundPage,
  personalData,
  shippingData,
  billingData,
  newCardAdded
};
