/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activeStep: 'shipping-method',
  invoiceInfo: {
    business_name: null,
    cuit: null
  },
  shippingAddress: {
    street: null,
    number: null,
    house: null,
    suite: null,
    floor: null,
    apple_block: null,
    city: null,
    reference: null,
    zipcode: null,
    state: null,
    state_id: 108,
    type: null,
    businessSegment: null, // VAYPOL || CITY,
    store_branch_id: null
  },
  billingAddress: {
    street: null,
    number: null,
    city: null,
    floor: null,
    zipcode: null,
    state: null,
    state_id: 108,
    country_id: null,
    reference: null,
    apple_block: null,
    house: null,
    suite: null,
    apartment: null,
    businessSegment: null
  },
  payment: {
    bin: null,
    error: null,
    cardType: null,
    spsToken: null,
    cardBrand: null,
    installmentId: null,
    installment: null,
    cardNumberLast4: null,
    deviceUniqueIdentifier: null,
    birthDate: null,
    saveCard: true,
    category: null
  },
  secondPayment: null,
  personalInfo: {
    first_name: null,
    last_name: null,
    dni: null,
    phone: null,
    user_id: null,
    tokenized_cards: [],
    email: null
  },
  persisted: false,
  newUser: false,
  logged: false,
  init: false
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setShippingType(state, action) {
      state.shippingAddress.type = action.payload;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    loadUserInfo(state, action) {
      if (action.payload.invoice_info) {
        state.invoiceInfo = {
          ...state.invoiceInfo,
          ...action.payload.invoice_info
        };
      }
      if (action.payload.personalInfo)
        state.personalInfo = {
          ...state.personalInfo,
          ...action.payload.personalInfo
        };
      if (action.payload.billingAddress)
        state.billingAddress = {
          ...state.billingAddress,
          ...action.payload.billingAddress
        };

      if (action.payload.shippingAddress)
        state.shippingAddress = {
          ...state.shippingAddress,
          ...action.payload.shippingAddress
        };
      if (action.payload.persisted) state.persisted = action.payload.persisted;
      state.logged = action.payload.logged;
      state.init = true;
    },
    updatePersonalInfo(state, action) {
      state.personalInfo = {
        ...state.personalInfo,
        ...action.payload
      };
    },
    updateInvoiceInfo(state, action) {
      state.invoiceInfo = {
        ...state.invoiceInfo,
        ...action.payload
      };
    },
    updateShippingAddress(state, action) {
      state.shippingAddress = { ...state.shippingAddress, ...action.payload };
    },
    updateBillingAddress(state, action) {
      state.billingAddress = { ...state.billingAddress, ...action.payload };
    },
    updatePayment(state, action) {
      state.payment = { ...state.payment, ...action.payload };
    },
    updateSecondPayment(state, action) {
      state.secondPayment = { ...state.secondPayment, ...action.payload };
    },
    paymentRestore(state) {
      state.payment = initialState.payment;
      state.secondPayment = initialState.secondPayment;
    },
    checkoutRestore() {
      return initialState;
    },
    initCheckout(state) {
      state.init = true;
    },
    setNewUser(state) {
      state.logged = false;
      state.newUser = true;
    },
    setCheckout(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});
export const {
  loadUserInfo,
  updateInvoiceInfo,
  updatePersonalInfo,
  updateShippingAddress,
  updateBillingAddress,
  updatePayment,
  updateSecondPayment,
  checkoutRestore,
  setActiveStep,
  setShippingType,
  paymentRestore,
  initCheckout,
  setNewUser,
  setCheckout
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
