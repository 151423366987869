import axios from '../../services/axiosInstance';
import errorHandler from '@/services/error/handler';

const get = async (
  filtros,
  page,
  token,
  storeId = process.env.NEXT_PUBLIC_STORE_ID
) => {
  try {
    const response = await axios.post(
      '/products',
      {
        page,
        code: storeId,
        filtros,
        keywords: filtros.keywords
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);
    return {
      success: false,
      error: message
    };
  }
};

const getByID = async (
  id,
  eventId,
  token,
  storeId = process.env.NEXT_PUBLIC_STORE_ID,
  stateId = 108,
  zipCode = '5500'
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const response = await axios.get(
      `/products/${id}?event_id=${eventId}&code=${storeId}&zipcode=${zipCode}&state_id=${stateId}`,
      { headers }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      redirectTo:
        err.response?.status === 302 ? err.response?.data?.redirect_to : null,
      error: message
    };
  }
};

const getWearItWith = async (
  productId,

  storeId = process.env.NEXT_PUBLIC_STORE_ID
) => {
  try {
    const response = await axios.get(
      `/products/${productId}/wear_it_with?code=${storeId}`
    );

    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

const getCustomersAlsoViewed = async (productId, storeId) => {
  try {
    const response = await axios.get(
      `/products/${productId}/customers_also_viewed?code=${storeId}`
    );

    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

const getPromotedProducts = async (token, storeId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const response = await axios.get(
      `/products/promotions_products?code=${storeId}`,
      { headers }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

const getNewnessProducts = async (token, storeId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const response = await axios.get(
      `/products/newness_products?code=${storeId}`,
      { headers }
    );

    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

const getPromotedCartProducts = async (token, storeId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const response = await axios.get(
      `/products/promotions_cart_products?code=${storeId}`,
      { headers }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err, true);

    return {
      success: false,
      error: message
    };
  }
};

const validateSlug = async (storeId, eventID, slug = '') => {
  try {
    const response = await axios.post('/products/validate_slug', {
      code: storeId,
      slug,
      event_id: eventID
    });
    return response.data;
  } catch (err) {
    const errors = errorHandler(err);

    return {
      success: false,
      errors
    };
  }
};

const sendFbEvent = async (type, options) => {
  try {
    const response = await axios.post('/events', {
      type,
      custom_data: options,
      event_id: options.landingEventID
    });
    return response.data;
  } catch (err) {
    const errors = errorHandler(err);

    return {
      success: false,
      errors
    };
  }
};

export default {
  get,
  getByID,
  getPromotedProducts,
  getPromotedCartProducts,
  getCustomersAlsoViewed,
  getWearItWith,
  getNewnessProducts,
  validateSlug,
  sendFbEvent
};
