import { useMemo } from 'react';
import { createReduxEnhancer } from '@sentry/nextjs';
import { configureStore } from '@reduxjs/toolkit';
import faqsReducer from '@/redux/faqs/faqsReducer';
import helpReducer from '@/redux/help/helpReducer';
import bannersReducer from '@/redux/banners/bannersReducer';
import storebranchesReducer from '@/redux/storebranches/storebranchesReducer';
import mapReducer from '@/redux/map/mapReducer';
import checkoutReducer, {
  initialState as checkoutInitialState
} from './checkout/checkoutReducer';
import cartReducer, {
  initialState as cartInitialState
} from './cart/cartReducer';
import productsReducer from './products/productsReducer';
import productDetailReducer from './productDetail/productDetailReducer';
import navBarReducer from './navBar/navBarReducer';
import trackingReducer from './tracking/trackingReducer';
import storeReducer from './store/storeReducer';
import newsletterReducer from './newsletter/newsletterReducer';
import streamingReducer from './streaming';
import machineInformationReducer from './machineInformation/machineInformationReducer';
import ssrInfoReducer from './ssrInfo/ssrInfoReducer';

let store;
// Redux connection with Sentry
const sentryReduxEnhancer = createReduxEnhancer({
  actionTransformer: (action) => (action.type === '@@INIT' ? null : action)
});
export default function initStore(initialState) {
  return configureStore({
    reducer: {
      faqs: faqsReducer,
      help: helpReducer,
      banners: bannersReducer,
      navBar: navBarReducer,
      map: mapReducer,
      cart: cartReducer,
      storebranches: storebranchesReducer,
      checkout: checkoutReducer,
      products: productsReducer,
      productDetail: productDetailReducer,
      tracking: trackingReducer,
      store: storeReducer,
      newsletter: newsletterReducer,
      streaming: streamingReducer,
      machineInformation: machineInformationReducer,
      ssrInfo: ssrInfoReducer
    },
    preloadedState: initialState,
    enhancers: [sentryReduxEnhancer]
  });
}

export const initializeStore = (preloadedState) => {
  let _store =
    store ??
    initStore({
      ...preloadedState,
      checkout: {
        ...checkoutInitialState,
        ...preloadedState?.checkout
      },
      cart: { ...cartInitialState, ...preloadedState?.cart }
    });

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
      faqs: { ...store.getState().faqs, ...preloadedState.faqs },
      checkout: {
        ...store.getState().checkout,
        ...preloadedState.checkout
      },
      cart: { ...store.getState().cart, ...preloadedState.cart }
    });

    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const _store = useMemo(() => initializeStore(initialState), [initialState]);
  return _store;
}
