/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */

import Router from 'next/router';
import api from '@/api/faqs';
import createUUID from '../createUUID';
import { getCookies } from '@/services/cookies';
import apiPixel from '@/api/vaypolPixel';

const vaypolAnalytics = async (referer) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 3);
  const utms = {};

  if (
    referer &&
    !referer.includes('localhost') &&
    !referer.includes(process.env.NEXT_PUBLIC_STORE_ID)
  ) {
    utms.utm_source = referer.split('//')[1];
    utms.referer = referer.split('//')[1];
    utms.utm_medium = 'referer';
  }

  Object.entries(Router.query).forEach(([key, value]) => {
    if (key.match(/^(utm_|gclid|gad_source|fbclid)/)) {
      utms[key] = value;
    }
  });
  if (Object.keys(utms).length > 0) {
    document.cookie = `VA_analytics=${JSON.stringify(
      utms
    )}; Path=/; Expires=${expirationDate.toUTCString()}`;
  }

  if (!localStorage.getItem('VA_session')) {
    const location = await api.getLocation();
    if (location.success) {
      localStorage.setItem(
        'VA_session',
        JSON.stringify({
          country: location.data.country,
          city: location.data.city,
          region: location.data.region,
          user: createUUID()
        })
      );
    }
  }
};

const getUTMInfo = () => {
  const { VA_analytics } = getCookies();
  try {
    return JSON.parse(VA_analytics);
  } catch {
    return JSON.parse('{"utm_source":"direct","utm_medium":"none"}');
  }
};
const getSessionInfo = () => {
  try {
    return JSON.parse(localStorage.getItem('VA_session') || '{}');
  } catch {
    return JSON.parse('{}');
  }
};
const runEvent = async (event) => {
  window.vaypol_analytics.processing = true;
  const sessionInfo = getSessionInfo();
  if (sessionInfo.user) {
    await apiPixel.sendEvent(
      event.name,
      event.options,
      getUTMInfo(),
      getSessionInfo()
    );
  }
  window.vaypol_analytics.processing = false;
  window.vaypol_analytics.queue.shift();
};

const run_analytics = async () => {
  for (let index = 0; index < window.vaypol_analytics?.queue?.length; index++) {
    await runEvent(window.vaypol_analytics.queue[index]);
  }
};

export default { vaypolAnalytics, run_analytics };
