/* eslint-disable react/no-unknown-property */
import 'styles/main.scss';
import { Montserrat } from 'next/font/google';

import { Router, useRouter } from 'next/router';
import {
  useState,
  useEffect
} from 'react'; /* eslint-disable react/jsx-props-no-spreading */
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import auth from '@/api/auth';
import cart from '@/api/cart';
import { useStore } from '../redux/store';
import { setCookie } from '@/services/cookies';
import {
  ThemeProvider,
  ErrorProvider,
  EventTracking
} from '@/components/organisms';
import {
  AsistantChat,
  ChangeModalCart,
  PageSpinner
} from '@/components/molecules';
import useScrollRestoration from '@/hooks/useScrollRestoration';
import useLiveInfo from '@/hooks/useLiveInfo';
import analytics from '@/utils/vaypolAnalytics';
import { verifyChangesInCart } from '@/redux/cart/cartReducer';
import andreaniApi from '@/api/location';

const montserrat = Montserrat({
  weight: ['100', '300', '400', '600', '700', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap'
});
const MyApp = ({ Component, pageProps }) => {
  const initial = pageProps.initialReduxState;
  if (pageProps.cartData && initial) {
    initial.cart = pageProps.cartData;
  }
  if (pageProps.userData && initial) {
    initial.checkout = pageProps.userData;
  }
  const store = useStore(initial);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const live = useLiveInfo(store);
  // guarda el scroll de la pagina anterior
  useScrollRestoration(router);
  const andreaniFlag = async () => {
    await setCookie('avaliableAndreani', pageProps.avaliableAndreani);
  };
  const setUserInfo = async () => {
    await analytics.vaypolAnalytics(pageProps.referer);
    setInterval(() => {
      analytics.run_analytics();
    }, 2000);
  };
  useEffect(() => {
    andreaniFlag();
    setUserInfo();
    Router.events.on('routeChangeStart', () => setLoading(true));
    Router.events.on('routeChangeComplete', () => setLoading(false));
    return () => {
      Router.events.off('routeChangeComplete', () => setLoading(false));
      Router.events.off('routeChangeStart', () => setLoading(true));
    };
  }, []);
  return (
    <Provider store={store}>
      <EventTracking>
        <ThemeProvider>
          <ErrorProvider>
            <style
              jsx
              global
            >{`
              * {
                font-family: ${montserrat.style.fontFamily};
              }
            `}</style>
            {loading && <PageSpinner />}
            {!router.asPath.includes('checkout') && <AsistantChat />}
            <Component
              {...pageProps}
              liveInfo={live}
            />
            <ChangeModalCart />
            {/* Container para inyectar luego la lib de toastify */}
            <div id="root-toastify" />
            <div id="portal-root" />
          </ErrorProvider>
        </ThemeProvider>
      </EventTracking>
    </Provider>
  );
};

MyApp.getInitialProps = async ({ ctx }) => {
  const referer = ctx?.req?.headers?.referer;
  const res = await andreaniApi.getProvinceInfoByZipCode('5500');
  let avaliableAndreani = true;
  if (!res.success) avaliableAndreani = false;
  let userData = { data: null };
  let cartData = { data: null };
  if (!ctx?.asPath?.includes('checkout')) {
    if (ctx.req?.cookies?.token) {
      userData = await auth.autoLogin(ctx, true);
      if (userData.data?.invoice_info) {
        const invoiceInfo = { ...userData.data.invoice_info };
        delete userData.data.invoice_info;
        userData.data.invoiceInfo = invoiceInfo;
      }
    }
    if (ctx.req?.cookies?.cartToken) {
      cartData = await cart.get(
        ctx.req?.cookies?.cartToken,
        ctx.req?.cookies?.user ? ctx.req?.cookies?.token : null
      );
    }
    if (userData.success) {
      userData.data.persisted = userData.data.persisted || false;
      userData.data.logged = true;
      userData.data.init = true;
    }
    if (cartData.success) {
      const changes = verifyChangesInCart(cartData.data, { line_items: [] });
      cartData.data.showChangeModal = { visible: !!changes, message: changes };
    }
  }
  return {
    pageProps: {
      referer,
      cartData: cartData.data,
      userData: userData.data,
      avaliableAndreani
    }
  };
};

export default MyApp;

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
};
