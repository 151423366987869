var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"LEcHus0TDyDhZ98Smgjfl"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { init } from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const MODE_ENV = process.env.MODE_ENV || process.env.NEXT_PUBLIC_MODE_ENV;
const ALLOW_SENTRY =
  process.env.ALLOW_SENTRY || process.env.NEXT_PUBLIC_ALLOW_SENTRY;
// const ALLOW_SENTRY_PROFILING =
//   process.env.ALLOW_SENTRY_PROFILING ||
//   process.env.NEXT_PUBLIC_ALLOW_SENTRY_PROFILING;

// const integrations = [];
// if (ALLOW_SENTRY_PROFILING === 'true') {
//   integrations.push(new BrowserProfilingIntegration());
// }

if (ALLOW_SENTRY === 'true') {
  init({
    // Adjunta todo el seguimiento de pila
    attachStacktrace: true,
    dsn: SENTRY_DSN,
    tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 0.5,
    environment: MODE_ENV,
    normalizeDepth: process.env.NEXT_PUBLIC_SENTRY_NORMALIZE_DEPTH || 100,
    profilesSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event.exception) {
        const errorMessage = hint?.originalException?.stack.toString();
        if (
          errorMessage?.match('ecommerce-modal') &&
          errorMessage?.match('classList')
        ) {
          return null;
        }

        return event;
      }
      return event;
    },
    // integrations,
    debug: false,
    replaysOnErrorSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'ui.click') {
        const element = document.querySelector(breadcrumb.message);
        const data = {};
        if (element.id) data.element = element.id;
        return {
          ...breadcrumb,
          data,
          message: breadcrumb.message
        };
      }
      return breadcrumb;
    }
  });
}
