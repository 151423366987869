import dynamic from 'next/dynamic';

const Skeleton = dynamic(() => import('@/components/atoms/Skeleton'));
export const ErrorProvider = dynamic(() => import('./ErrorProvider'));
export const Banners = dynamic(() => import('./Banners'));
export const BannerSlider = dynamic(() => import('./BannerSlider'));
export const CategoriesGroup = dynamic(() => import('./CategoriesGroup'));
export const CategoriesBanners = dynamic(() => import('./CategoriesBanners'));
export const CategoriesSlider = dynamic(() => import('./CategoriesSlider'));
export const BrandsLogoSlider = dynamic(() => import('./BrandsLogoSlider'));
export const BrandsResults = dynamic(() => import('./BrandsResults'));
export const BrandsGallery = dynamic(() => import('./BrandsGallery'));
export const Events = dynamic(() => import('./Events'));
export const Filter = dynamic(() => import('./Filters/FilterSort/Filter'));
export const FilterItem = dynamic(() =>
  import('./Filters/FilterSort/FilterItem')
);

export const CheckoutButtonModal = dynamic(() =>
  import('@/components/organisms/Header/CartIcon/CheckoutButtonModal')
);
export const FilterOption = dynamic(() =>
  import('./Filters/FilterSort/FilterOption')
);
export const FilterOptionSize = dynamic(() =>
  import('./Filters/FilterSort/FilterOptionSize')
);
export const ModalFilter = dynamic(() =>
  import('./Filters/FilterSort/ModalFilter')
);
export const Sort = dynamic(() => import('./Filters/FilterSort/Sort'));
export const SortSelect = dynamic(() =>
  import('./Filters/FilterSort/SortSelect')
);
export const FilterSort = dynamic(() => import('./Filters/FilterSort'));
export const Info = dynamic(() => import('./Footer/Info'));
export const Newsletter = dynamic(() => import('./Footer/Newsletter'));
export const Safety = dynamic(() => import('./Footer/Safety'));

export const Footer = dynamic(() => import('./Footer'));
export const Form = dynamic(() => import('./Form'));
export const GalleryBrand = dynamic(() => import('./GalleryBrand'));
export const GalleryProduct = dynamic(() => import('./GalleryProduct'));
export const FooterBanners = dynamic(() => import('./FooterBanners'));
export const CartModal = dynamic(() => import('./Header/CartIcon/CartModal'));
export const CuponCode = dynamic(() => import('./Header/CartIcon/CuponCode'));
export const CartIcon = dynamic(() => import('./Header/CartIcon'));
export const Dropdown = dynamic(() => import('./Header/UserIcon/Dropdown'));
export const UserIcon = dynamic(() => import('./Header/UserIcon'));
export const Header = dynamic(() => import('./Header'));
export const HeaderLite = dynamic(() => import('./HeaderLite'));

export const HelpSection = dynamic(() => import('./HelpSection'));
export const HelpSectionDesktop = dynamic(() => import('./HelpSectionDesktop'));
export const LogoSlider = dynamic(() => import('./LogoSlider'));
export const PaymentResume = dynamic(() => import('./PaymentResume'));
export const ProductList = dynamic(() => import('./ProductList'));
export const ProductSlider = dynamic(() => import('./ProductSlider'), {
  loading: () => (
    <Skeleton
      width="200px"
      height="200px"
      dark
    />
  )
});
export const RenderInfo = dynamic(() => import('./RenderInfo'));
export const ServicesSlider = dynamic(() => import('./ServicesSlider'));

export const SideMenu = dynamic(() => import('./SideMenu'));
export const SubCategoryResults = dynamic(() => import('./SubCategoryResults'));
export const ThemeProvider = dynamic(() => import('./ThemeProvider'));
export const EventTracking = dynamic(() => import('./EventTracking'));
export const CardPreview = dynamic(() =>
  import('./WrapperPreview/CardPreview')
);
export const DocumentPreview = dynamic(() =>
  import('./WrapperPreview/DocumentPreview')
);
export const WrapperPreview = dynamic(() => import('./WrapperPreview'));

export const TokenizedCard = dynamic(() => import('./TokenizedCard'));
