/* eslint-disable camelcase */
import analytics from '../analytics';
import fbpixel from '../fbpixel';
import gtm from '../googleTagManager';
import normalizeProduct from '../normalizeProduct';
import vaypolPixel from '../vaypolTracker';
import api from '@/api/products';

const search = (keywords) => {
  if (window.gtag) {
    analytics.search(keywords);
  }
  vaypolPixel.search('search', { keywords });
};

const notFoundPage = (keywords) => {
  if (window.gtag) {
    analytics.notFound(keywords);
  }
  vaypolPixel.notFoundPage(keywords);
};

const pageview = (url) => {
  if (window.fbq) {
    fbpixel.pageview();
  }
  if (window.gtag) {
    analytics.pageview(url);
  }
  if (window.dataLayer) {
    gtm.pageview(url);
  }
  vaypolPixel.pageview(url);
};

const viewProduct = ({ productDetail, eventId }) => {
  if (productDetail) {
    const product = normalizeProduct(productDetail);
    if (window.fbq) {
      fbpixel.viewContent(product, eventId);
    }
    if (window.gtag) {
      analytics.viewItem(product);
    }
    vaypolPixel.viewProduct(productDetail.id);
  }
};

const viewLanding = ({ landing, landingEventID, data }) => {
  if (window.fbq) {
    api.sendFbEvent('ViewLanding', data);
    fbpixel.viewLanding(landing, landingEventID);
  }
  if (window.gtag) {
    analytics.viewPromotion(landing);
  }
  vaypolPixel.viewLanding(landing);
};

const viewHome = () => {
  if (window.gtag) {
    analytics.viewHome();
  }
  vaypolPixel.viewHome();
};

const viewCart = () => {
  if (window.gtag) {
    analytics.viewCart();
  }
  vaypolPixel.viewCart();
};

const addPaymentInfo = (type) => {
  if (window.gtag) {
    analytics.addPaymentInfo(type);
  }
  vaypolPixel.addPaymentInfo(type);
};

const personalData = (type) => {
  if (window.gtag) {
    analytics.personalData(type);
  }
  vaypolPixel.personalData(type);
};

const shippingData = (type) => {
  if (window.gtag) {
    analytics.shippingData(type);
  }
  vaypolPixel.shippingData(type);
};

const billingData = (type) => {
  if (window.gtag) {
    analytics.billingData(type);
  }
  vaypolPixel.billingData(type);
};

const newCardAdded = () => {
  if (window.gtag) {
    analytics.newCardAdded();
  }
  vaypolPixel.newCardAdded();
};

const beginCheckout = () => {
  if (window.gtag) {
    analytics.beginCheckout();
  }
  vaypolPixel.beginCheckout();
};

const addToCart = ({ productDetail, eventId }) => {
  if (productDetail) {
    const product = normalizeProduct(productDetail);
    if (window.fbq) {
      fbpixel.addToCart(product, eventId);
    }
    if (window.gtag) {
      analytics.addToCart(product);
    }
    vaypolPixel.addToCart(productDetail.id);
  }
};

const removeFromCart = (product) => {
  // fbpixel.removeFromCart(product); en un futuro quizas lo quieran
  if (window.gtag && product) {
    analytics.removeFromCart(product);
  }
  vaypolPixel.removeFromCart(product.id);
};

const signup = (eventId) => {
  if (window.fbq) {
    fbpixel.completeRegistration(eventId);
  }
  if (window.gtag) {
    analytics.signup();
  }
  vaypolPixel.signup();
};

const subscribe = (eventId) => {
  if (window.fbq) {
    fbpixel.subscribe(eventId);
  }
  // analytics.subscribe(); en un futuro quizas lo quieran
};

const purchase = ({ cart, eventId }) => {
  if (window.fbq && cart) {
    fbpixel.purchase(cart.attributes, eventId);
  }
  if (window.gtag && cart) {
    analytics.purchase(cart.attributes);
  }
  vaypolPixel.purchase(cart.id);
};

const purchaseTry = () => {
  if (window.gtag) {
    analytics.purchaseTry();
  }
  vaypolPixel.purchaseTry();
};

const conversion = (cart) => {
  if (window.gtag && cart) {
    analytics.conversion(cart.attributes);
  }
  vaypolPixel.conversion(cart.id);
};

const setUserData = (cart) => {
  if (window.gtag && cart) {
    analytics.setUserData(cart);
  }
};

export default {
  search,
  pageview,
  viewProduct,
  viewLanding,
  viewHome,
  viewCart,
  addPaymentInfo,
  beginCheckout,
  addToCart,
  removeFromCart,
  signup,
  subscribe,
  purchase,
  purchaseTry,
  conversion,
  notFoundPage,
  setUserData,
  personalData,
  shippingData,
  billingData,
  newCardAdded
};
